export default appdata => {
  appdata.parameters = {
    version: null,    
    general: {
      access: {
        userCanAccess: true,
        autoDispatch: false
      },
      finance: {
        initialCapital: 100000,
        initialTreasury: 100000,
        taxRates: true,
      },
      overdraft: {
        overdraftAuthorization: 50000,
        agiosRate: 5,
        placementRate: 3,
      },
      loans: {
        baseRate: 1,
        maxDuration: 60,
        fees: 1000,
        initial: false,
        initialValue: 0,
        initialDuration: 0,
        initialBaseRate: 0,
        earlyRepaymentPenaltyRate: 5
      },
      affacturage: {
        operationalCommission: 1,
        financialCommission: 3,
        fees: 1500
      },
      maxPaimentDelay: 120,
      maxReduction: 100,
      suppliersPaimentDelay: 0,
      firingCost: 25,
      fixFiringCost: 500,
      hiringCost: 0,
      fixHiringCost: 0
    },


    market: {
      contracts: {
        unhappyLossRisk: 20,
        lossRisk: 5,
        coeffs: {
          price: 5,
          efficiency: 5,
          reputation: 2,
          happiness: 1,
          ecology: 1,
          comercialRelation: 3
        },
        inertia: {
          price: 30,
          efficiency: 30,
          reputation: 30,
          happiness: 30,
          ecology: 30,
          comercialRelation: 30
        }
      },
      rh: {
        risk: {
          unhappyResignation: 20,
          resignation: 5,
          unhappyAbsent: 10,
          absent: 2,
          unhappyAbandon : 5,
          abandon : 2,
        },
        coeff: {
          hours: 5,
          reputation: 5,
          salaries: 5,
          formations: 2,
          happiness: 3,
          attribution: 3,
          ecology: 1,
          comercialRelation: 2
        },
        inertia: {
          hours: 30,
          reputation: 5,
          salaries: 30,
          formations: 30,
          happiness: 30,
          attribution: 30,
          ecology: 30,
          comercialRelation: 30
        }
      }
    },


    roles: [],


    display: {
      pages: {
        company: true,
        messageCenter: true,
        depot: true,
        buildings: true,
        politics: true,
        staff: true,
        trainings: true,
        offers: true,
        contracts: true,
        communications: true,
        bank: true,
        sales: true,
        finance: true,
        data: true
      },
      functionality: {
        company: {
          companyName: true,
          companyLogo: true,
          playerRole: true
        },
        buildings: {
          buyRent: true,
          sellCancelLease: true,
          upgrade: true
        },
        politic: {
          updatePolitic: true
        },
        staffs: {
          hire: true,
          fire: true,
          payIncrease: true
        },
        offers: {
          updateOffer: true
        },
        contracts: {
          addContract: true,
          cancelContract: true,
          reduction: true
        },
        communication: {
          start: true,
          stop: true
        },
        bank: {
          loan: true,
          payBack: true,
          affacturage: true
        }
      }
    }
  }

  return appdata
}
