<template>
  <v-app v-if="isReady >= 3 || (!$route.meta.accessRules || !$route.meta.accessRules.accountNeeded)">
    <template v-if="$route.meta.displayNav && profileStore.profile">
      <span v-if="$vuetify.display.mdAndUp">
        <UserNavigation />
        <AdminNavigation v-if="profileStore.profile.role !== 'User'" />
      </span>
      <span v-else>
        <MobileNavigation v-if="profileStore.profile" ref="mobileNavigation" />
      </span>
    </template>
    <v-app-bar height="50" elevation="3" class="d-flex flex-row align-center justify-center" v-if="$route.meta.displayNav && profileStore && profileStore.profile">
      <v-btn color="primary" icon v-if="!$vuetify.display.mdAndUp" @click="$refs.mobileNavigation.toggleMenu()">
        <v-icon size="40">mdi-menu</v-icon>
      </v-btn>
      <Header v-if="$route.meta.displayNav" />
    </v-app-bar>
    <v-main>
      <v-container class="py-0" style="height: 100%; max-width:100%;">
        <router-view class="router-view" />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
  <v-app v-else>
    <v-main>
      <v-img :src="require('@/assets/images/mfbPoster.png')" cover style="height: 100%; width: 100%; position: absolute; top: 0; left: 0;"></v-img>
      <v-container class="py-0 d-flex flex-column align-center justify-center" style="height: 100%; max-width:100%;" v-if="$vuetify.display.mdAndUp">
        <v-card class="pa-10" style="border-radius: 50px; opacity: .9;">
          <h1 class="text-primary mb-3">Chargement ...</h1>
          <v-progress-circular color="primary" indeterminate :size="200" :width="10">
            <v-img class="mx-2" height="125" width="125" :src="require('@/assets/images/Logo.svg')"></v-img>
          </v-progress-circular>
        </v-card>
      </v-container>
      <v-container class="py-0 d-flex flex-column align-center justify-center" style="height: 100%; max-width:100%;" v-else>
        <v-card class="pa-10" style="border-radius: 50px; opacity: .9;">
          <h3 class="text-primary mb-3">Chargement ...</h3>
          <v-progress-circular color="primary" indeterminate :size="100" :width="6">
            <v-img class="mx-2" height="70" width="70" :src="require('@/assets/images/Logo.svg')"></v-img>
          </v-progress-circular>
        </v-card>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import packageJSON from '@/../package.json'

import { getAuth } from '@firebase/auth'

import initAppdata from '@/assets/functions/initAppdata'

import { useProfileStore } from '@/stores/profile'
import { useCollectionStore } from '@/stores/collections'
import { useAppStore } from '@/stores/appdata'

import Profile from '@/classes/Profile'
import Application from '@/classes/Application'
import Appdata from '@/classes/Appdata'
import Team from '@/classes/Team'
import Period from '@/classes/Period'

import UserNavigation from '@/components/common/UserNavigation.vue'
import AdminNavigation from '@/components/common/AdminNavigation.vue'
import MobileNavigation from '@/components/common/MobileNavigation.vue'
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'

export default {
  name: "App",
  components: {
    UserNavigation,
    AdminNavigation,
    MobileNavigation,
    Footer,
    Header,
  },
  data() {
    return {
      profileStore: useProfileStore(),
      collections: useCollectionStore(),
      appStore: useAppStore(),
      packageJSON,
      unsub: [],
      nesteadUnsub: [],
      nestead2Unsub: [],
      mobileMenu: false,
      isReady: 0,
      isOnGoodVersion: false,
      loaded: {
        teams: false,
        periods: false
      }
    }
  },
  async mounted() {
    getAuth().onAuthStateChanged(async user => {
      if (user) {
        this.unsub.push(await Profile.listenById(user.uid, async profile => {
          if (profile && profile.currentApps != undefined && profile.currentApps.app_myFirstBusiness != undefined) {
            this.profileStore.profile = profile
            if (profile.role !== 'SuperAdmin') {
              this.profileStore.profile.role = profile.organisations[profile.currentOrganisation].role
            }
            this.isReady++

            for (const unsub of this.nesteadUnsub) {
              if (typeof unsub === 'function') {
                unsub()
              }
            }
            this.nesteadUnsub.push(await Appdata.listenByApplication(profile.currentApps.app_myFirstBusiness, async appdata => {
              this.appStore.appdata = appdata
              if (!this.appStore.appdata.parameters) {
                // console.time('Appdata successfully save')
                this.appStore.appdata = initAppdata(this.appStore.appdata)
                await this.appStore.appdata.save()
                // console.timeLog('Appdata successfully save')
              }
              if(!this.appStore.appdata.parameters.version){
                this.appStore.appdata.parameters.version = parseInt(packageJSON.version.split('.')[0])
                await this.appStore.appdata.save()
              }
              if(this.appStore.appdata.parameters.version < parseInt(packageJSON.version.split('.')[0])){
                this.isOnGoodVersion = false
              }else{
                this.isOnGoodVersion = true
              }

              if(!this.isOnGoodVersion){
                this.$router.push('/BadVersion')
              }

              this.isReady++
            }))
            this.nesteadUnsub.push(await Application.listenById(profile.currentApps.app_myFirstBusiness, async application => {
              this.appStore.app = application
              if (this.appStore.app) {
                if (this.appStore.app.archived) {
                  this.$router.push('/NoCurrentApp')
                } else {
                  for (const unsub of this.nestead2Unsub) {
                    if (typeof unsub === 'function') {
                      unsub()
                    }
                  }

                  // PERIODS
                  this.nestead2Unsub.push(await Period.listenAll(async res => {
                    if (res.length === 0) {
                      const new_period = Period.initOne()
                      await new_period.save()
                    }
                    
                    let availablePeriods = res

                    this.collections.allPeriods = availablePeriods

                    if (this.profileStore.profile.role === 'User') {
                      availablePeriods = availablePeriods.filter(period => period.available)
                    }

                    this.collections.periods = availablePeriods

                    this.profileStore.selectedPeriod = this.collections.periods[this.collections.periods.length -1]

                    this.loaded.periods = true
                    this.collections.loaded = this.loaded.periods && this.loaded.teams
                  }))

                  // TEAMS
                  this.nestead2Unsub.push(await Team.listenAll(res => {
                    this.collections.teams = res.sort((a, b) => a.name.localeCompare(b.name))

                    if (this.profileStore.profile.role === 'User') {
                      const currentTeam = res.find(team => team.users.includes(this.profileStore.profile.id))
                      this.profileStore.team = currentTeam
                      this.profileStore.selectedTeam = currentTeam
                    } else {
                      if (!this.profileStore.selectedTeam) {
                        this.profileStore.selectedTeam = this.collections.teams[0]
                      }else{
                        this.profileStore.selectedTeam = this.collections.teams.find(team => team.id === this.profileStore.selectedTeam.id)
                      }
                    }
                    if (this.profileStore.profile.role === 'User' && !this.profileStore.team){
                      this.$router.push('/NoCurrentTeam')
                    }

                    this.loaded.teams = true
                    this.collections.loaded = this.loaded.periods && this.loaded.teams
                  }))
                }
              }
              this.isReady++
            }))

          }else{
            if(!profile){
              this.logout()
            }
            else if(profile.currentApps == undefined || profile.currentApps.app_myFirstBusiness == undefined){
              this.$router.push('/NoCurrentApp')
            }else{
              this.logout()
            }
          }
        }))
      }
      else this.logout()
    })
  },
  methods: {
    logout() {
      this.isReady = 0
      this.profileStore.profile = null
      this.profileStore.team = null
      this.appStore.appId = null
      if (!this.$route.meta.accessRules || (this.$route.meta.accessRules && this.$route.meta.accessRules.accountNeeded)) {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style>
.router-view {
  max-height: calc(100vh - 130px);
}
</style>
