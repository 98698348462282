let environements = {
  dev: {
      apiKey: "AIzaSyDCxjsiINGpDgrLcY9HOMbVb-QtRl1ICvo",
      authDomain: "lp-backend-dev-70722.firebaseapp.com",
      projectId: "lp-backend-dev-70722",
      storageBucket: "lp-backend-dev-70722.appspot.com",
      messagingSenderId: "6475726073",
      appId: "1:6475726073:web:221ff09e5c79430072b628"
  },
  prod: {
      apiKey: "AIzaSyA1ky7RMI_CdgbbW3OU94Okd3zf38eUuLw",
      authDomain: "lp-backend-prod-29965.firebaseapp.com",
      projectId: "lp-backend-prod-29965",
      storageBucket: "lp-backend-prod-29965.appspot.com",
      messagingSenderId: "772066696647",
      appId: "1:772066696647:web:c521ce8a2b90465fe18de2"
  }
}

export default environements