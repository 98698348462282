import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import getCollectionPath from '@/assets/functions/getCollectionPath'

const db = getFirestore()
const collectionName = 'teams/'

function docToInstance(doc) {
  const data = doc.data()
  return data ? new Team(doc.id, data.name, data.publicName, data.logo, data.users, data.roles) : null
}

export default class Team {
  constructor(id, name, publicName, logo, users, roles) {
    this.id = id
    this.name = name
    this.publicName = publicName
    this.logo = logo
    this.users = users
    this.roles = roles
  }

  static initOne(name){
    let team = new Team(null, name, name, null, [], {})
    return team
  }

  async save() {
    const new_team = {
      name: this.name,
      publicName: this.publicName,
      logo: this.logo,
      users: this.users,
      roles : this.roles,
    }

    if (this.id) {
      await updateDoc(doc(db, await getCollectionPath(collectionName), this.id), new_team)
    } else {
      await addDoc(collection(db, await getCollectionPath(collectionName)), new_team)
    }
  }

  static async listenAll(callback) {
    onSnapshot(collection(db, await getCollectionPath(collectionName)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }

  static async listenById(id, callback) {
    return onSnapshot(doc(db, await getCollectionPath(collectionName), id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }

  static async getAll(){
    let response = await getDocs(collection(db, await getCollectionPath(collectionName)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
}


  async delete(){
      let documentRef = doc(db, await getCollectionPath(collectionName), this.id)
      deleteDoc(documentRef)
  }


}
